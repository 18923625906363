<template>
  <div class="uploadFile" @click.stop>
    <van-uploader v-model="fileList" :after-read="afterRead" @delete="delImg" :multiple="multiple" :before-read="beforeRead" :max-count="maxCount">
      <div class="addBox">
        <slot>
          <svg-icon class="addIcon" iconClass="upload_add"></svg-icon>
        </slot>
      </div>
    </van-uploader>
  </div>
</template>

<script>
import { Toast, Uploader } from 'vant';
import { uploadImg } from '@/api/app';

export default {
  props: {
    maxCount: {
      type: String,
      default: '99',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  name: 'uploadFile',
  components: {
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      fileList: [],
      maxSize: 1024 * 1024 * 10,
    };
  },
  methods: {
    beforeRead(file) {
      if (file.length > this.maxCount) {
        Toast(`一次最多上传${this.maxCount}张图`);
        return false;
      }
      if (file.length) {
        file.map(async (item) => {
          if (item.size > this.maxSize) {
            Toast('单个图片最大为10M');
            return false;
          }
        });
      }
      if (file.size > this.maxSize) {
        Toast('单个图片最大为10M');
        return false;
      }
      return true;
    },
    // 图片上传
    async afterRead(file) {
      if (file.length) {
        file.map(async (item, i) => {
          let newFile = await this.compress(item.file);
          item.file = newFile;
          await this.uplpadImg(item);
        });
      } else {
        let newFile = await this.compress(file.file);
        file.file = newFile;
        this.uplpadImg(file);
      }
    },
    //图片上传
    async uplpadImg(fileInfo) {
      let req = new FormData();
      req.append('upload', fileInfo.file);
      fileInfo.status = 'uploading';
      fileInfo.message = '上传中...';
      try {
        let ret = await this.$Api(uploadImg, req);
        if (ret.code == 200) {
          fileInfo.status = '';
          fileInfo.message = '';
          this.$emit('uploadSuccess', ret.data.coverImg);
          return;
        }
        fileInfo.status = 'failed';
        fileInfo.message = '上传失败';
      } catch (e) {
        fileInfo.status = 'failed';
        fileInfo.message = '上传失败';
      }
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(',');
      var imgtype = '';
      var base64String = '';
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            if (result.length <= 200 * 1024) {
              let blob = selt.convertBase64ToBlob(result);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
              return;
            }
            img.onload = function () {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = '#fff';
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL('image/jpeg', 0.3);
              let blob = selt.convertBase64ToBlob(ndata);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
            };
          };
        });
      }
    },
    // 删除回调
    delImg(file, detail) {
      this.$emit('delImg', detail.index);
    },
    // 清理图片列表
    clearImg() {
      this.fileList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadFile {
  .addBox {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    background: #ffffff;
    color: #333333;
    border: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    .addIcon {
      width: 19px;
      height: 19px;
    }
  }

  /deep/ .van-uploader__preview-delete {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    border: 1px solid #ffffff;
    .van-uploader__preview-delete-icon {
      top: 0;
      right: 0;
      transform: scale(0.7);
    }
  }
  /deep/ .van-uploader__mask {
    border-radius: 6px;
  }
  /deep/ .van-uploader__preview {
    width: 100px;
    height: 100px;
    border-radius: 6px;
  }

  /deep/ .van-image {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    box-sizing: border-box;
  }
}
</style>
